<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen tw-text-[#82868B]">
    <div
      class="tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
    >
      <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-10">
        <div class="tw-w-full tw-flex tw-justify-between">
          <button
            id="tw-p-[0.3rem] tw-rounded-full tw-self-start tw-bg-[#82868b] tw-text-white tw-cursor-pointer"
            title="Voltar para o inicio"
            @click="voltar()"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </button>
        </div>
        <header class="tw-flex tw-flex-col tw-items-center tw-mt-40 lg:tw-mt-16">
          <h1
            class="tw-font-extrabold tw-text-[#82868B] tw-text-[3rem] lg:tw-text-[2.3rem] tw-text-center tw-mt-16 lg:tw-mt-10"
          >
            Para iniciar, insira seu
            <span :style="{ color: institutoParams.corTotem }">CPF</span>
          </h1>
        </header>

        <div class="tw-border-b-2 tw-w-full tw-text-[#82868B]/20 tw-p-8">
          <cleave
            id="cpf"
            class="border-b tw-py-3 tw-border-[#82868B] tw-p-6 tw-mt-10 tw-w-full tw-text-center focus:tw-outline-none tw-text-4xl :placeholder:tw-text-[#82868B]/20 tw-text-[#82868B]"
            type="text"
            inputmode="numeric"
            placeholder="Digite seu cpf"
            :options="options.cleaveCPF"
            v-model="cpf"
            @onchange="isCPFValid"
          />
          <p v-if="cpfInvalido" class="tw-text-red-500 tw-text-center tw-mt-1 tw-text-2xl">
            CPF inválido. Por favor, verifique e tente novamente.
          </p>
        </div>
        <button
          @click="enviarCpf()"
          :style="{ background: institutoParams.corTotem }"
          id="botaoSeguir"
          :class="` tw-text-white  tw-mt-24 lg:tw-mt-10 tw-p-10 tw-rounded-full tw-font-bold tw-text-2xl lg:tw-text-lg`"
        >
          <feather-icon icon="ArrowRightIcon" size="32" />
        </button>
      </div>
    </div>
    <footer class="tw-w-full tw-text-center tw-mt-10">
      <span class="brand-logo">
        <b-img
          :src="institutoParams.logo != null ? institutoParams.logo : require(`@/assets/images/logo/Novos/logoCliente.png`)"
          alt="logo"
          variant="light-primary"
          badge
          badge-variant="success"
          class="tw-h-40 lg:tw-h-24 badge-minimal"
          ref="refPreviewEl"
          rounded
        />
      </span>
    </footer>
  </div>
</template>
<script>
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarCPF } from '@/libs/utils'
  export default {
    components: {
      Cleave,
    },
    data() {
      return {
        institutoParams: {
          corTotem: '#0000CC',
        },
        cpf: null,
        cpfInvalido: false,
        options: {
          cleaveCPF: {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
        },
      }
    },
    mounted() {
      this.institutoParams = JSON.parse(localStorage.getItem('institutoParams'))
    },

    methods: {
      voltar() {
        this.$emit('updateCurrent', 1)
      },
      isCPFValid() {
        if (!this.cpf) return false

        // Remove os delimitadores para validação
        const cpfNumerico = this.cpf.replace(/[^\d]+/g, '')

        if (cpfNumerico.length !== 11 || /^(\d)\1+$/.test(cpfNumerico)) {
          return false
        }

        let soma = 0
        let resto

        for (let i = 1; i <= 9; i++) {
          soma += parseInt(cpfNumerico.substring(i - 1, i)) * (11 - i)
        }

        resto = (soma * 10) % 11

        if (resto === 10 || resto === 11) resto = 0
        if (resto !== parseInt(cpfNumerico.substring(9, 10))) return false

        soma = 0
        for (let i = 1; i <= 10; i++) {
          soma += parseInt(cpfNumerico.substring(i - 1, i)) * (12 - i)
        }

        resto = (soma * 10) % 11

        if (resto === 10 || resto === 11) resto = 0
        if (resto !== parseInt(cpfNumerico.substring(10, 11))) return false

        return true
      },
      async enviarCpf() {
        this.cpfInvalido = !this.isCPFValid()
        if (this.cpfInvalido) {
          return
        }

        await useJwt
          .post(`totem/totemSegurado`, {
            cpf: formatarCPF(this.cpf),
          })
          .then((res) => {
            this.$emit('updateTomador', res.data)
            this.$emit('updateCurrent', 3)
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.$message.error(' CPF não encontrado! Favor entrar em contato com instituto!')
            } else {
              this.$message.error('Houve um erro ao buscar o segurado!')
            }
          })
      },
    },
  }
</script>
<style></style>
