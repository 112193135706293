<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen tw-text-[#82868B]">
    <div
      class="tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
    >
      <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 lg:tw-gap-6">
        <div class="tw-w-full tw-flex tw-justify-between">
          <button
            id="tw-p-[0.3rem] tw-rounded-full tw-self-start tw-bg-[#82868b] tw-text-white tw-cursor-pointer"
            title="Voltar para o inicio"
            @click="voltar()"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </button>
          <button
            id="tw-p-[0.5rem]  tw-rounded-full tw-self-start  tw-text-white tw-cursor-pointer"
            :style="{ backgroundColor: 'red', color: 'white', borderRadius: '100%', padding: '5px' }"
            title="Voltar para o inicio"
            @click="fechar()"
          >
            <feather-icon icon="XIcon" size="24" />
          </button>
        </div>
        <header class="tw-flex tw-flex-col tw-items-center">
          <h4 class="tw-font-extrabold tw-text-[#82868B] tw-text-[3rem] lg:tw-text-[2rem] tw-text-center">
            <span :style="{ color: institutoParams.corTotem }">Simulação </span>do seu empréstimo
          </h4>
        </header>

        <div class="tw-border-b-2 tw-w-full tw-text-[#82868B]/20 tw-px-14 tw-flex tw-flex-col tw-gap-5 lg:tw-gap-1">
          <div
            class="tw-w-full tw-text-[#82868B] tw-p-2 lg:tw-p-1 tw-outline-none placeholder:tw-text-[#C7CCD2] tw-text-2xl lg:tw-text-xl tw-mt-2 lg:mt-0"
            style="border-bottom: solid 2px #c7ccd2"
            placeholder="Insira a quantidade de parcelas"
          >
            <p class="tw-text-[#82868B]/70 tw-text-2xl lg:tw-text-xl tw-font-bold">Valor à Receber</p>
            <p class="tw-text-[#82868B] tw-font-medium tw-text-2xl lg:tw-text-xl">
              {{ formatarValor(calcularValorTotal()) }}
            </p>
          </div>

          <div
            class="tw-w-full tw-text-[#82868B] tw-p-2 lg:tw-p-1 tw-outline-none placeholder:tw-text-[#C7CCD2] tw-text-2xl lg:tw-text-xl tw-mt-2 lg:mt-0"
            style="border-bottom: solid 2px #c7ccd2"
            placeholder="Insira a quantidade de parcelas"
          >
            <p class="tw-text-[#82868B]/70 tw-text-2xl lg:tw-text-xl tw-font-bold">Número de parcelas</p>
            <p class="tw-text-[#82868B] tw-font-medium tw-text-2xl lg:tw-text-xl">
              {{ this.proposta.quantidadeDeParcelas + ' x ' + formatarValorReais(proposta.valorDaParcela) }}
            </p>
          </div>

          <div
            class="tw-w-full tw-text-[#82868B] tw-p-2 lg:tw-p-1 tw-outline-none placeholder:tw-text-[#C7CCD2] tw-text-2xl lg:tw-text-xl tw-mt-2 lg:mt-0"
            style="border-bottom: solid 2px #c7ccd2"
            placeholder="Insira a quantidade de parcelas"
          >
            <p class="tw-text-[#82868B]/70 tw-text-2xl lg:tw-text-xl tw-font-bold">Juros</p>
            <p class="tw-text-[#82868B] tw-font-medium tw-text-2xl lg:tw-text-xl">
              {{ formatarValorPorcentagem(institutoParams.taxaEmprestimoMensal) }}
            </p>
          </div>
        </div>

        <div>
          <p
            class="tw-bg-red-400 tw-border-2 tw-border-red-700 tw-p-8 tw-mx-12 tw-rounded-md tw-text-redtw-bg-red-500 tw-text-white tw-border-red tw-text-2xl"
          >
            Os valores apresentados são uma simulação. As condições finais podem variar.
          </p>

          <div class="tw-w-full tw-flex tw-justify-center tw-gap-10">
            <button
              v-if="!criouSms"
              id="botaoSeguir"
              @click="exibirPopup(1), sms()"
              :class="`tw-bg-[#82868B] tw-text-white  lg:tw-mt-2 tw-p-6 lg:tw-p-4 tw-rounded-full tw-font-bold tw-text-3xl lg:tw-text-lg`"
            >
              Enviar via SMS
            </button>
            <div v-else class="tw-p-5 tw-px-7 tw-bg-green-600 tw-text-white tw-flex tw-items-center tw-rounded-full">
              <feather-icon icon="CheckCircleIcon" size="24" />
            </div>
            <button
              v-if="!criouProposta"
              :style="{ background: institutoParams.corTotem }"
              @click="exibirPopup(2), criarProposta()"
              id="botaoSeguir"
              :class="` tw-text-white lg:tw-mt-2  tw-p-6 lg:tw-p-4 tw-rounded-full tw-font-bold tw-text-3xl lg:tw-text-lg`"
            >
              Quero Saber Mais
            </button>
            <div v-else class="tw-p-5 tw-px-7 tw-bg-green-600 tw-text-white tw-flex tw-items-center tw-rounded-full">
              <feather-icon icon="CheckCircleIcon" size="24" />
            </div>
          </div>

          <div class="tw-w-full tw-flex tw-justify-center tw-gap-10 tw-mt-7">
            <h2 class="tw-text-2xl">
              Voltando para tela inicial em <span class="tw-text-red-600"> {{ contador }}</span> segundos
            </h2>
          </div>
        </div>
      </div>
    </div>
    <footer class="tw-w-full tw-text-center tw-mt-10">
      <span class="brand-logo">
        <b-img
          :src="institutoParams.logo != null ? institutoParams.logo : require(`@/assets/images/logo/Novos/logoCliente.png`)"
          alt="logo"
          variant="light-primary"
          badge
          badge-variant="success"
          class="tw-h-40 lg:tw-h-24 badge-minimal"
          ref="refPreviewEl"
          rounded
        />
      </span>
    </footer>

    <div class="tw-w-full tw-absolute tw-h-screen tw-bg-black/20" v-if="popup">
      <div class="tw-absolute tw-bottom-0 tw-w-full tw-rounded-t-3xl">
        <div v-if="tipoPopup == 1" class="tw-bg-white tw-rounded-t-3xl">
          <div class="tw-bg-[#28C76F] tw-p-6 text-center tw-text-white tw-flex tw-items-center tw-justify-between tw-px-20">
            <h1 class="tw-text-white tw-text-4xl">Enviado</h1>
            <feather-icon icon="XIcon" size="32" @click="exibirPopup()" />
          </div>
          <div class="tw-py-20">
            <p class="m-0 text-center tw-text-3xl tw-px-20 mb-2">
              Sua simulação foi enviada para: <br />
              <span class="tw-font-semibold">{{ dadosTomador.telefone }}</span>
            </p>
            <p class="m-0 text-center tw-text-2xl tw-px-20">
              Caso o número esteja incorreto, entre em contato com o instituto.
            </p>
          </div>
        </div>
        <div v-else class="tw-bg-white tw-rounded-t-3xl">
          <div class="tw-bg-[#28C76F] tw-p-6 text-center tw-text-white tw-flex tw-items-center tw-justify-between tw-px-20">
            <h1 class="tw-text-white tw-text-4xl">Entraremos em Contato</h1>
            <feather-icon icon="XIcon" size="32" @click="exibirPopup()" />
          </div>
          <p class="tw-py-20 m-0 text-center tw-text-2xl tw-px-20">
            Sua simulação foi <span class="tw-text-[#28C76F]">enviada</span> para nossa equipe. Logo
            <span class="tw-text-[#28C76F]">entraremos em contato</span> para concluir sua proposta.
          </p>
        </div>
      </div>
    </div>

    <div class="tw-w-full tw-flex tw-items-center tw-justify-center tw-h-screen tw-bg-black/20 tw-absolute" v-if="popupFechar">
      <div class="tw-bg-white tw-w-[50%] tw-h-[30%] lg:tw-w-[20%] tw-rounded-3xl">
        <div class="tw-mx-4 tw-gap-5 tw-m-2 tw-flex tw-items-center tw-flex-col tw-text-center tw-h-full tw-justify-center">
          <h1>O tempo da sua <span :style="{ color: institutoParams.corTotem }">simulação</span> acabou</h1>
          <p class="tw-text-2xl">Por favor, inicie uma nova simulação.</p>
          <button
            @click="fechar()"
            :style="{ background: institutoParams.corTotem }"
            class="tw-text-white tw-px-16 tw-py-2 tw-rounded-lg tw-font-medium tw-text-xl tw-cursor-pointer"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { primeiroNome } from '@/libs/utils'
  import { formatarValor, formatarValorPorcentagem, formatarValorReais } from '@/utils/Functions/Formatacao/Formatacao'
  import Cleave from 'vue-cleave-component'
  export default {
    components: {
      Cleave,
    },
    props: {
      quantidadeParcelas: {
        type: Number,
        required: true,
      },
      valorDesejado: {
        type: Number,
        required: true,
      },
      tipoEmprestimo: {
        type: Number,
        required: true,
      },
      dadosTomador: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        formatarValorReais,
        institutoParams: {
          corTotem: '#0000CC',
          taxaEmprestimoMensal: 0,
        },
        contador: 180,
        options: {
          cleaveCPF: {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
        },
        calculou: false,
        proposta: {
          valorTotal: 0,
          valorDaParcela: 0,
          quantidadeDeParcelas: 0,
          valorTotalEmprestadoAoSegurado: 0,
          valorFloat: 0,
          valorIOF: 0,
          taxaIOF: 0,
          quantidadeDiasIOF: 0,
        },
        tipoPopup: 1,
        popup: false,
        formaCalculo: 'valorDoEmprestimo',
        criouSms: false,
        criouProposta: false,
        popupFechar: false,
      }
    },
    async mounted() {
      this.institutoParams = JSON.parse(localStorage.getItem('institutoParams'))
      this.iniciarContagemRegressiva()
      await this.tipoCalculo()
    },

    methods: {
      voltar() {
        this.$emit('updateCurrent', 3)
      },
      formatarValor,
      formatarValorPorcentagem,
      calcularValorTotal() {
        if (this.formaCalculo === 'valorDoEmprestimo') {
          return this.valorDesejado
        } else if (this.formaCalculo === 'valorDaParcela') {
          return this.quantidadeParcelas * this.valorDesejado
        }
      },
      iniciarContagemRegressiva() {
        const intervalo = setInterval(() => {
          if (this.contador > 0) {
            this.contador--
            if (this.contador == 10) {
              this.popupFechar = true
            }
          } else {
            clearInterval(intervalo)
            this.dadosTomador = null
            this.$emit('updateCurrent', 1)
          }
        }, 1000)
      },
      async tipoCalculo() {
        this.calculou = true
        switch (this.tipoEmprestimo) {
          case 1:
            this.formaCalculo = 'valorDoEmprestimo'
            await this.calculo()
            break
          case 2:
            this.formaCalculo = 'valorDaParcela'
            await this.calculo()
            break
        }

        this.calculandoParcela = true
      },
      async calculo() {
        try {
          const params = {
            seguradoId: this.dadosTomador.segurado[0].id,
            institutoId: this.institutoParams.institutoId,
            valor: this.valorDesejado,
            parcelas: this.quantidadeParcelas,
            formaCalculo: this.formaCalculo,
          }

          await useJwt
            .post('totem/totemCalculo', params)
            .then((response) => {
              this.proposta = response.data
            })
            .catch((error) => {
              this.$message.error('Erro ao calcular empréstimo')
              this.calculandoEmprestimo = false
              console.error(error)
            })
        } catch (error) {
          this.$message.error('Erro ao calcular empréstimo')
          this.calculandoEmprestimo = false
          console.error(error)
        }
      },
      exibirPopup(tipo) {
        this.popup = !this.popup
        this.tipoPopup = tipo
      },
      async criarProposta() {
        this.criouProposta = true
        const params = {
          seguradoId: this.dadosTomador.segurado[0].id,
          institutoId: this.institutoParams.institutoId,
          saldoDevedor: this.proposta.valorTotalEmprestadoAoSegurado,
          valorDaParcela: this.proposta.valorDaParcela,
          parcelas: this.quantidadeParcelas,
          formaCalculo: this.formaCalculo,
          valorFloat: this.proposta.valorFloat,
          valorIOF: this.proposta.valorIOF,
          taxaIOF: this.proposta.taxaIOF,
          quantidadeDiasIOF: this.proposta.quantidadeDiasIOF,
          valorBaseCalculo: this.valorDesejado,
        }

        await useJwt.post('/totem/totemGerarProposta', params)
      },
      async sms() {
        this.criouSms = true
        const data = {
          msg: `Olá, ${primeiroNome(this.dadosTomador.nomeCompleto)}.

  Sua simulação foi realizada com sucesso! O valor total é R$ ${this.proposta.valorTotal}, com parcelas de R$ ${
            this.proposta.valorDaParcela
          } em ${this.proposta.quantidadeDeParcelas} vezes.

  Estamos à disposição para qualquer dúvida!`,

          telefone: this.dadosTomador.telefone,
        }
        await useJwt.post('/totem/enviarSms', data)
      },
      fechar() {
        this.dadosTomador = null
        this.$emit('updateCurrent', 1)
      },
    },
  }
</script>
<style></style>
