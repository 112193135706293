<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen tw-text-[#82868B]">
    <div
      class="tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
    >
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
        <div class="tw-w-full tw-flex tw-justify-between">
          <button
            id="tw-p-[0.3rem] tw-rounded-full tw-self-start tw-text-white tw-cursor-pointer"
            title="Voltar para o inicio"
            @click="voltar()"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </button>
          <button
            id="tw-p-[0.5rem]  tw-rounded-full tw-self-start  tw-text-white tw-cursor-pointer"
            :style="{ backgroundColor: 'red', color: 'white', borderRadius: '100%', padding: '5px' }"
            title="Voltar para o inicio"
            @click="fechar()"
          >
            <feather-icon icon="XIcon" size="24" />
          </button>
        </div>
        <header class="tw-flex tw-flex-col tw-items-center tw-mt-28 lg:tw-mt-0">
          <h1
            class="tw-font-extrabold tw-text-[#82868B] tw-text-[3.5rem] lg:tw-text-[2.5rem] tw-text-center tw-mt-16 lg:tw-mt-10"
          >
            Agora, de <span :style="{ color: institutoParams.corTotem }"> quanto </span> você precisa?
          </h1>
        </header>

        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-px-12">
          <div class="tw-text-center">
            <div
              style="border-bottom: solid 2px #c7ccd2"
              class="tw-flex tw-w-full tw-text-[#82868B] tw-p-2 tw-outline-none tw-text-center tw-text-2xl lg:tw-text-xl"
            >
              <span class="tw-ml-6 tw-text-3xl lg:tw-text-xl">R$</span>
              <input
                class="tw-outline-none tw-w-full tw-text-2xl tw-text-center lg:tw-text-xl tw-text-[#82868B] placeholder:tw-text-[#C7CCD2]"
                placeholder="Insira o valor que você quer emprestar"
                v-model="valorDesejado"
                inputmode="numeric"
                v-number="number"
                @input="validarValorMaximo"
              />
            </div>
            <div class="tw-flex tw-justify-center" :class="errorValor && 'tw-text-red-500'">
              <p class="tw-text-2xl lg:tw-text-xl tw-mt-2 tw-mr-2 tw-font-semibold" v-if="errorValor">Atenção:</p>
              <p class="tw-text-2xl lg:tw-text-xl tw-mt-2">
                Valor
                <span
                  :style="{ color: errorValor ? '#EF4444' : institutoParams.corTotem }"
                  :class="errorValor && 'tw-text-red-500'"
                  >máximo</span
                >

                {{ formatarValorReais(institutoParams.valorMaximoEmprestimo) }}
              </p>
            </div>
          </div>
          <div>
            <h1 class="tw-font-extrabold tw-text-[#82868B] tw-text-[2rem] lg:tw-text-[1.5rem] tw-text-center">
              Em quantas <span :style="{ color: institutoParams.corTotem }">parcelas </span> você quer pagar?
            </h1>
          </div>
          <div class="tw-text-center">
            <input
              type="number"
              class="tw-w-full tw-text-2xl tw-text-[#82868B] tw-p-2 tw-outline-none tw-text-center placeholder:tw-text-[#C7CCD2] lg:tw-text-xl tw-mt-2"
              style="border-bottom: solid 2px #c7ccd2"
              placeholder="Insira a quantidade de parcelas"
              v-model.number="quantidadeParcelas"
              :min="1"
              :max="institutoParams.limiteParcelas"
              @input="validarLimiteParcela"
            />

            <div class="tw-flex tw-justify-center" :class="errorParcelas && 'tw-text-red-500'">
              <p class="tw-text-2xl lg:tw-text-xl tw-mt-2 tw-mr-2 tw-font-semibold" v-if="errorParcelas">Atenção:</p>
              <p class="tw-text-2xl lg:tw-text-xl tw-mt-2">
                Quantidade
                <span
                  :style="{ color: errorParcelas ? '#EF4444' : institutoParams.corTotem }"
                  :class="errorParcelas && 'tw-text-red-500'"
                  >máxima</span
                >: {{ institutoParams.limiteParcelas }} Meses
              </p>
            </div>
          </div>
        </div>

        <button
          id="botaoSeguir"
          @click="finalizar()"
          :disabled="!quantidadeParcelas || !valorDesejado || errorParcelas || errorValor"
          :style="{
            background:
              !quantidadeParcelas || !valorDesejado || errorParcelas || errorValor ? '#c7ccd2' : institutoParams.corTotem,
          }"
          :class="` tw-text-white tw-mt-24 lg:tw-mt-10 tw-p-10 tw-rounded-full tw-font-bold tw-text-2xl lg:tw-text-lg`"
        >
          <feather-icon icon="ArrowRightIcon" size="32" />
        </button>
      </div>
    </div>
    <footer class="tw-w-full tw-text-center tw-mt-10">
      <span class="brand-logo">
        <b-img
          :src="institutoParams.logo != null ? institutoParams.logo : require(`@/assets/images/logo/Novos/logoCliente.png`)"
          alt="logo"
          variant="light-primary"
          badge
          badge-variant="success"
          class="tw-h-40 lg:tw-h-24 badge-minimal"
          ref="refPreviewEl"
          rounded
        />
      </span>
    </footer>
  </div>
</template>
<script>
  import { formatarValorReais } from '@/utils/Functions/Formatacao/Formatacao'
  import { formatarParaNumber } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  export default {
    components: {
      Cleave,
    },
    props: {
      dadosTomador: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        institutoParams: {
          corTotem: '#0000CC',
        },

        quantidadeParcelas: null,
        valorDesejado: null,
        number: 0,
        errorValor: false,
        errorParcelas: false,
      }
    },
    mounted() {
      this.institutoParams = JSON.parse(localStorage.getItem('institutoParams'))
      this.institutoParams.limiteParcelas = this.dadosTomador.numeroMaximoParcelas
    },

    methods: {
      formatarValorReais,
      voltar() {
        this.$emit('updateCurrent', 3)
      },
      fechar() {
        this.dadosTomador = null
        this.$emit('updateCurrent', 1)
      },
      validarValorMaximo() {
        const valor = formatarParaNumber(this.valorDesejado)
        const valorMaximo = this.institutoParams.valorMaximoEmprestimo
        if (valor > valorMaximo) {
          this.errorValor = true
        } else {
          this.errorValor = false
        }
      },
      validarLimiteParcela(event) {
        const valor = parseInt(event.target.value, 10)
        if (valor > this.institutoParams.limiteParcelas) {
          this.errorParcelas = true
        } else {
          this.errorParcelas = false
        }
      },
      finalizar() {
        if (this.errorValor || this.errorParcelas) {
          return
        }
        this.$emit('updateCurrent', 6)
        this.$emit('tipoCalculo', 2)
        this.$emit('updateValores', {
          valorDesejado: formatarParaNumber(this.valorDesejado),
          quantidadeParcelas: this.quantidadeParcelas,
        })
      },
    },
  }
</script>
<style></style>
