<template>
  <div>
    <div
      v-if="isLoading"
      class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen tw-text-[#82868B]"
    >
      <div
        class="tw-bg-[#F8F8F8] tw-p-10 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[90%] lg:tw-w-[600px] tw-min-h-[800px]"
      >
        <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h1 class="tw-text-2xl tw-animate-pulse" :style="{ color: institutoParams.corTotem }">Carregando...</h1>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="totemError"
        class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen tw-text-[#82868B]"
      >
        <div
          class="tw-bg-[#F8F8F8] tw-p-10 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
        >
          <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-mt-24">
            <header class="tw-flex tw-flex-col tw-items-center">
              <img
                src="@/assets/images/illustration/calculadoraErro.svg"
                alt="Imagem calculadora"
                class="tw-w-[500px] lg:tw-w-[450px]"
              />
              <h1
                class="tw-font-extrabold tw-text-[#82868B] tw-text-[4rem] lg:tw-text-[2.5rem] tw-text-center tw-mt-16 lg:tw-mt-10"
              >
                Parâmetros não configurados.
              </h1>
              <p class="tw-text-2xl lg:tw-text-lg tw-text-center">
                Parece que alguns parâmetros necessários não estão configurados. Entre em contato com o suporte do instituto se
                precisar de ajuda
              </p>
            </header>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen tw-text-[#82868B]"
      >
        <div
          class="tw-bg-[#F8F8F8] tw-p-10 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
        >
          <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-mt-48 lg:tw-mt-20">
            <header class="tw-flex tw-flex-col tw-items-center">
              <img
                :src="
                  institutoParams.logo != null ? institutoParams.logo : require(`@/assets/images/logo/Novos/logoCliente.png`)
                "
                alt="Imagem calculadora"
                class="tw-w-[500px] lg:tw-w-[450px] tw-rounded-3xl"
              />
              <h1
                class="tw-font-extrabold tw-text-[#82868B] tw-text-[4rem] lg:tw-text-[2.5rem] tw-text-center tw-mt-16 lg:tw-mt-10"
              >
                Simular <span :style="{ color: institutoParams.corTotem }">Empréstimo</span> Consignado
              </h1>
              <p class="tw-text-2xl lg:tw-text-lg tw-text-center">
                Simule rapidamente seu empréstimo consignado, escolha suas condições e veja os detalhes em tempo real.
              </p>
            </header>
            <button
              :style="{ background: institutoParams.corTotem }"
              :class="` tw-text-white tw-mt-24 lg:tw-mt-10 tw-p-10 lg:tw-p-6 tw-px-10 tw-rounded-[3rem] tw-font-bold tw-text-3xl lg:tw-text-lg tw-mb-24 lg:tw-mb-10`"
              @click="simular()"
            >
              Quero simular
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber } from '@/libs/utils'
  export default {
    components: {
      formatarParaNumber,
    },

    data() {
      return {
        siglaDoInstituto: {
          corTotem: '#0000CC',
        },
        institutoParams: {
          logo: null,
          valorMaximoEmprestimo: 0,
        },
        totemError: false,
        isLoading: false,
      }
    },
    mounted() {
      this.siglaDoInstituto = new URLSearchParams(window.location.search).get('sigla')
      if (!this.siglaDoInstituto) this.siglaDoInstituto = window.location.href.toLowerCase().split('?sigla=')[1]

      this.carregarParams(this.siglaDoInstituto)
    },
    methods: {
      carregarParams(sigla) {
        this.isLoading = true
        useJwt
          .get(`totem/${sigla}`)
          .then((res) => {
            if (res.data) {
              const parametros = res.data
              const totem = parametros.Totem
              if (!totem || !parametros) {
                this.totemError = true
                this.$message.error('Favor configurar parâmetros do totem!')
              }

              this.institutoParams.valorMaximoEmprestimo = formatarParaNumber(totem.valorMaximoEmprestimoTotem)
              this.institutoParams.corTotem = totem.corTotem

              this.institutoParams.logo =
                parametros.logoCalculadora?.logo !== undefined ? parametros.logoCalculadora?.logo : null

              this.institutoParams.sigla = this.siglaDoInstituto
              this.institutoParams.institutoId = parametros.id
              this.institutoParams.taxaEmprestimoMensal = parametros.taxaEmprestimoMensal
              localStorage.setItem('institutoParams', JSON.stringify(this.institutoParams))
            } else {
              this.totemError = true
              this.$message.error('Falha ao consultar parâmetros do totem!')
              console.error('Falha ao consultar parametros do totem!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.totemError = true
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      simular() {
        this.$emit('updateCurrent', 2)
      },
    },
  }
</script>
<style lang=""></style>
